<script>
import { required } from "vuelidate/lib/validators";
import { posService } from "../../../helpers/cattel.service";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      Positions: posService.getPositions().then((data) => {
        if (data.message.length) {
          this.Positions = data.message;
        } else {
          this.Positions = [
            {
              id: "0",
              position: "داده‌ای وجود ندارد",
              positiontime: "داده‌ای وجود ندارد",
            },
          ];
        }
      }),
      NewPos: {
        pos_name: "",
        description: "",
      },
      modeltype: "add",
      PosID: 0,
      posindex: 0,
      convertId: 0,
      modeltitle1: "افزودن جایگاه",
      submittedAddPos: false,
      showmodalAddPos: false,
      submittedDel: false,
      showmodalDel: false,
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
    };
  },
  validations: {
    NewPos: {
      pos_name: { required },
    },
  },
  methods: {
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    DeletePos(posId, idx) {
      this.PosID = posId;
      this.posindex = idx;
      this.modeltype = "delete";
      this.showmodalDel = true;
    },
    AddPos() {
      this.NewPos = {pos_name: "",description: ""};
      this.modeltype = "add";
      this.modeltitle1 = "افزودن جایگاه";
      this.showmodalAddPos = true;
    },
    EditPos(id, index) {
      this.modeltype = "update";
      this.PosID = id;
      this.posindex = index;
      posService.getPos(id).then((data) => {
        this.NewPos.pos_name = data.message.position;
        this.modeltitle1 = "ویرایش " + data.message.position;
        this.NewPos.description = data.message.description;
      });
      this.showmodalAddPos = true;
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submittedAddPos = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const PosName = this.NewPos.pos_name;
        const Desc = this.NewPos.description;
        if (this.modeltype == "add") {
          posService.addPos(PosName, Desc).then((data) => {
            this.swalWithBootstrapButtons.fire({
              text: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
            if (this.Positions[0]['id'] == "0") {
              this.Positions = [];
            }
            if (data.success) {              
              this.Positions.push(data.pos);
            }
          });
        } else if (this.modeltype == "update") {
          posService.editPos(this.PosID.PosName, Desc).then((data) => {
            this.swalWithBootstrapButtons.fire({
              text: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
            this.Positions[this.posindex].position = PosName;
          });
        }

        this.showmodalAddPos = false;
      }
      this.submittedAddPos = false;
      this.NewPos = {pos_name: "",description: ""};
    },

    handleSubmitDel() {
      this.submittedDel = true;
      this.swalWithBootstrapButtons
        .fire({
          title: "آیا مطمئن هستید ؟",
          text: "شما دیگر نمی‌توانید این را برگردانید",
          icon: "warning",
          confirmButtonText: "حذف",
          cancelButtonText: "لغو",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            posService.deletePos(this.PosID, this.convertId).then((data) => {
              this.Positions.splice(this.posindex, 1);
              this.swalWithBootstrapButtons.fire({
                text: data.message,
                icon: data.err,
                confirmButtonText: "تایید",
              });
              if (data.success) {
                this.showmodalDel = false;
                this.submittedDel = false;
              }
            });
          }
        });
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submittedAddPos = false;
      this.showmodalAddPos = false;
      this.submittedDel = false;
      this.showmodalDel = false;
      this.NewPos = {pos_name: "",description: ""};
    },
    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a
                href="javascript:void(0);"
                class="btn btn-success ml-2 mb-2"
                v-if="CheckAccessOnce('AddPos')"
                @click="AddPos()"
              >
                <i class="mdi mdi-plus mr-2"></i> افزودن جایگاه
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
              >
                <thead class="thead-light">
                  <tr>
                    <th class="text-center">شناسه</th>
                    <th class="text-center">نام جایگاه</th>
                    <th class="text-center">زمان ثبت</th>
                    <th class="text-center">عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in Positions" :key="index">
                    <td class="text-center">{{ item.id }}</td>
                    <td class="text-center">{{ item.position }}</td>
                    <td class="text-center">{{ item.positiontime }}</td>
                    <td class="text-center">
                      <a
                        href="javascript:void(0);"
                        class="mr-1 text-primary"
                        v-b-tooltip.hover
                        v-if="CheckAccessOnce('GetPos')"
                        @click="EditPos(item.id, index)"
                        title="ویرایش"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="mr-1 text-danger"
                        v-b-tooltip.hover
                        v-if="CheckAccessOnce('DeletePos')"
                        @click="DeletePos(item.id, index)"
                        title="حذف"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodalAddPos"
      :title="modeltitle1"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="pos_name">نام جایگاه</label>
          <input
            id="pos_name"
            v-model="NewPos.pos_name"
            type="text"
            class="form-control"
            placeholder="نام جایگاه"
            :class="{
              'is-invalid': submittedAddPos && $v.NewPos.pos_name.$error,
            }"
          />
          <div
            v-if="submittedAddPos && !$v.NewPos.pos_name.required"
            class="invalid-feedback"
          >
            نام جایگاه الزامی است
          </div>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewPos.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" v-if="CheckAccessOnce('EditPos')" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-2"
      v-model="showmodalDel"
      title="حذف جایگاه"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmitDel">
        <div class="form-group">
          <label for="posdes">انتقال دام ها به جایگاه</label>
          <select
            id="posdes"
            type="posdes"
            name="posdes"
            v-model="convertId"
            class="form-control custom-select"
          >
            <option
              v-for="(item, index) in Positions"
              :key="index"
              :value="item.id"
            >
              {{ item.position }}
            </option>
          </select>
        </div>
        <div class="text-right">
          <button type="submit" v-if="CheckAccessOnce('DeletePos')" class="btn btn-success">تایید</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>